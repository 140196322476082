import React from 'react';

import SEO from '../components/seo';
import SectionA from '../features/contact/sectionA';
import SectionB from '../features/contact/sectionB';

export default function HomePage() {
  return (
    <>
      <SEO title="Contact" />
      <SectionA />
      <SectionB />
    </>
  );
}
