import React from 'react';
import { Container, Grid, makeStyles, Typography, TextField, Button } from '@material-ui/core';

import PhoneIcon from '../../assets/icons/phone.inline.svg';
import EmailIcon from '../../assets/icons/email.inline.svg';
import HourIcon from '../../assets/icons/hour.inline.svg';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4),
      textAlign: 'center',
    },
  },

  spacer: {
    marginBottom: theme.spacing(6),
  },

  form: {
    padding: theme.spacing(7, 4, 5),
    backgroundColor: '#F7F7F7',
    borderRadius: 10,
  },

  button: {
    color: '#fff',
    backgroundColor: '#E88154',
  },

  textContainer: {
    maxWidth: 406,
  },

  contactContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
  },

  contactInfo: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 50,
    '& > *:first-child': {
      marginRight: 50,
      [theme.breakpoints.down('sm')]: {
        marginRight: 'auto',
      },
    },
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'right',
      },
    },
  },

  contactWrapper: {
    paddingTop: 70,
  },
}));

export default function SectionA() {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <div className={classes.contactContainer}>
              <div className={classes.textContainer}>
                <Typography variant="h1" paragraph>
                  Stay in touch!
                </Typography>
                <Typography variant="h2">
                  Fill in the form and our Team will get back to you within 24 hours.
                </Typography>
              </div>
              <div className={classes.contactWrapper}>
                <div className={classes.contactInfo}>
                  <PhoneIcon />
                  <Typography variant="h2">+1 (312) 763-4064</Typography>
                </div>
                <div className={classes.contactInfo}>
                  <EmailIcon />
                  <Typography variant="h2">info@famguru.app</Typography>
                </div>
                <div className={classes.contactInfo}>
                  <HourIcon />
                  <div>
                    <Typography>Mon-Fri</Typography>
                    <Typography variant="h2">09am-7pm</Typography>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <form className={classes.form} action="https://formspree.io/f/xjvjopqq" method="post">
              <TextField
                required
                variant="filled"
                InputProps={{ disableUnderline: true }}
                label="Name"
                name="name"
                placeholder="Your Name"
                fullWidth
                className={classes.spacer}
              />
              <TextField
                required
                variant="filled"
                InputProps={{ disableUnderline: true }}
                label="Email"
                name="email"
                type="email"
                placeholder="Your Email"
                fullWidth
                className={classes.spacer}
              />
              <TextField
                required
                variant="filled"
                InputProps={{ disableUnderline: true }}
                label="Message"
                name="message"
                placeholder="Your Message"
                rows={20}
                multiline
                fullWidth
                className={classes.spacer}
              />
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  className={classes.button}
                  type="submit"
                >
                  <Typography variant="h6">Send the message</Typography>
                </Button>
              </div>
            </form>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
